<template>
    <Menu v-slot="{ open }" as="div" class="flex text-left">
        <MenuButton
            ref="triggerRef"
            :disabled="disabled"
            :class="['w-full', disabled && 'pointer-events-none']"
            as="div"
            @click.stop="onMenuToggle(open)"
        >
            <slot />
        </MenuButton>

        <div ref="popperRef" class="absolute z-[1] py-2">
            <Transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-in"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
                @before-enter="updatePopper"
            >
                <MenuItems
                    as="div"
                    class="w-full min-w-40"
                    :class="menuClasses"
                >
                    <div
                        :class="[
                            'w-full overflow-hidden rounded-md p-1 shadow ring-1 ring-black ring-opacity-5 focus:outline-none',
                            dark ? 'bg-gray-700' : 'bg-white',
                        ]"
                    >
                        <slot name="items-prepend" />
                        <BaseMenuItem
                            v-for="(item, i) in items"
                            :key="item.text || i"
                            :text="item.text"
                            :icon="item.icon"
                            :icon-class="item.iconClass"
                            :danger="item.danger"
                            :as="item.as"
                            :to="item.to"
                            @click="item.onclick?.($event)"
                        />
                    </div>
                </MenuItems>
            </Transition>
        </div>
    </Menu>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Menu, MenuButton, MenuItems } from '@headlessui/vue'
import popper from '~/utils/popper'
import type { MenuItem as MenuItemEntry } from './MenuItem.vue'
import type { Placement } from '@popperjs/core/lib/enums'

const props = defineProps({
    items: {
        type: Array as PropType<MenuItemEntry[]>,
        required: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    /** Desired placement for menu list. If placement becomes impossible, the best one will be picked */
    placement: {
        type: String as PropType<Placement>,
        default: 'bottom-end' as Placement,
    },
    dark: {
        type: Boolean,
        default: false,
    },
    menuClasses: {
        type: String,
        default: '',
    },
})

const triggerRef = ref<InstanceType<typeof MenuButton> | null>(null)
const popperRef = ref<HTMLElement | null>(null)

const { createPopper, destroyPopper, updatePopper } = popper()

onUnmounted(() => destroyPopper())

function onMenuToggle(wasOpened: boolean) {
    const triggerEl = triggerRef.value?.$el as HTMLElement
    const popperEl = popperRef.value as HTMLElement

    if (!wasOpened) {
        createPopper(triggerEl, popperEl, {
            placement: props.placement,
            strategy: 'fixed',
        })
    }
}
</script>
